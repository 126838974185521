/* eslint-disable max-classes-per-file */

class MfeConfigManagerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CustomerFederatedModuleError';
  }
}

class InvalidInputError extends MfeConfigManagerError {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidInputError';
  }
}

export { MfeConfigManagerError, InvalidInputError };
