/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable no-new-func */
/* eslint-disable no-continue */

import * as yup from 'yup';
import { merge as _merge } from 'lodash';
import { InvalidInputError } from './mfe-config-manager-errors';

type Config = {
  themeOverrides: Record<string, unknown> | null;
  mfeLink: string;
};

enum InputTypes {
  Json,
  Url,
  Invalid,
}

const parsedModuleInfoSchema = yup.object().shape({
  moduleConfig: yup
    .array(
      yup.object().shape({
        mfeEndpoint: yup.string().url().required(),
        theme: yup.object().nullable(),
        condition: yup.string().nullable().default(null),
      })
    )
    .required(),
});

/**
 * Rappresenta le configurazioni dei moduli federati (MFE) di un Customer
 * Gestisce la valutazioni di quali moduli sono attivi in base alle condizioni espresse in ogni modulo.
 * Gestisce il merge degli overrides del tema per ogni modulo federato.
 */
export class MfeConfigManager {
  /**
   * @private
   * Contiene tutte le configurazioni dei moduli, le chiavi sono i module name. Es: "common-home"
   * @type {Record<string, Config>}
   */
  private config: Record<string, Config> = {};

  /**
   * Costruttore della classe
   * Parsa tutte le configurazioni dei moduli in input, validando il contenuto delle config ed eseguendo l'evaluation del campo `condition` per ciascuna di loro. La prima che evaluates a true e' quella selezionata.
   *
   * @param {Record<string, string>} customerModules - Object che contiene le configs per i moduli. Le chiavi sono i module names (`common-home`...), i valori le configurazioni sottoforma di stringhe.
   */
  constructor(customerModules: Record<string, string>) {
    Object.entries(customerModules).forEach(([key, value]) => {
      const parsedValue = this.parseCustomerFederatedModules(
        value
      );
      if (!parsedValue) {
        return;
      }

      this.config[key] = parsedValue;
    });
  }

  /**
   * Ottiene la configurazione per il modulo passato.
   *
   * @param {string} moduleName - Nome del modulo del quale si vuole ottenere la configurazione.
   * @returns {Config} La configurazione del modulo.
   * @throws {InvalidInputError} Se il moduleName passato non e' valido.
   */
  public getModuleConfig(moduleName: string): Config {
    if (!(moduleName in this.config)) {
      throw new InvalidInputError(
        `Invalid federated module name provided: ${moduleName}`
      );
    }

    return this.config[moduleName];
  }

  /**
   * Ottiene la configurazione di tutti i moduli.
   *
   * @returns {Record<string, Config>} Record di configurazione di ogni modulo.
   */
  public getConfig(): Record<string, Config> {
    return this.config;
  }

  /**
   * Ottiene un oggetto con i nomi dei moduli ed i relativi URL.
   *
   * @returns {Record<string, string>} Oggetto con i nomi dei moduli ed i relativi URL.
   */
  public getModuleUrls(): Record<string, string> {
    return Object.entries(this.config).reduce<Record<string, string>>(
      (output, [moduleName, config]) => {
        output[moduleName] = config.mfeLink;
        return output;
      },
      {}
    );
  }

  /**
   * Controlla se sono disponibili configurazioni per i moduli federati
   *
   * @returns {boolean}
   */
  public hasConfig(): boolean {
    return Object.keys(this.config).length > 0;
  }

  /**
   * Controlla se sono disponibili overrides di tema nelle configurazioni dei moduli federati
   *
   * @returns {boolean}
   */
  public hasThemeOverrides(): boolean {
    return Object.values(this.config).some((config) => config.themeOverrides);
  }

  /**
   * Effettua il deep merge tra gli overrides di uno specificato modulo con il resto degli argomenti.
   *
   * @param {string} targetModule - Il nome del module del quale si vuole fare il merge degli overrides.
   * @param {...Object} sources - Oggetti addizionali con i quali mergiare gli overrides.
   * @returns {Object | null} Il tema mergiato o null se non esistono overrides per il modulo.
   */
  public mergeWithModuleThemeOverrides(
    targetModule: string,
    ...sources: Array<Object>
  ): Object | null {
    const targetOverrides = this.getModuleConfig(targetModule).themeOverrides;

    if (!sources?.length) {
      return targetOverrides;
    }
    if (!targetOverrides) {
      return targetOverrides;
    }

    return _merge(targetOverrides, ...sources);
  }

  /**
   * Effettua il deep merge tra gli overrides di tutti i moduli in config ed un target in argomento.
   *
   * @param {Object | string} target - Oggetto o JSON.stringify di un oggetto con cui mergiare tutti gli overrides dei moduli presenti in config.
   * @returns {Object} Il target in input mergiato con tutti gli overrides dei moduli presenti in config.
   */
  public mergeWithAllThemeOverrides(target?: Object | string): Object {
    if (typeof target === 'string') {
      target = JSON.parse(target);
    }

    const sources: Array<Record<string, unknown>> = [];

    Object.values(this.config).forEach((config) => {
      if (config.themeOverrides) {
        sources.push(config.themeOverrides);
      }
    });

    return _merge(target ?? {}, ...sources);
  }

  /**
   * Effettua il parsing e validazione di un MFE config in ingresso come stringa.
   * Supporta la gestione legacy delle MFE config (il valore e' URL dal quale importare il modulo federato) e la gestione delle config dinamiche con gli overrides del tema.
   *
   * @private
   * @param {string} [input] - Input di partenza con la configurazione da parsare.
   * @returns {Config | null} La configurazione o null.
   * @throws {InvalidInputError} Se l'input non rispetta il formato che ci si aspetta: url o JSON.stringify della configurazione del modulo.
   */
  private parseCustomerFederatedModules(input?: string): Config | null {
    if (!input) {
      return null;
    }

    // Normalizza la stringa: rimuove backtics eventuali backtics all'inizio e alla fine
    input = input.replace(/^`|`$/g, '');

    // Determina il tipo di input:
    // - Url: url dello storage da cui ottenere il bundle del modulo federato.
    // - Json: JSON string con le configurazioni del modulo.
    // - Invalid: nessuna dei 2 tipi validi.
    const determineInputType = (value: string): InputTypes => {
      try {
        yup.string().url().validateSync(value);
        return InputTypes.Url;
      } catch {
        try {
          const parsed = JSON.parse(value);
          return parsed !== null &&
            typeof parsed === 'object' &&
            !Array.isArray(parsed)
            ? InputTypes.Json
            : InputTypes.Invalid;
        } catch {
          return InputTypes.Invalid;
        }
      }
    };

    switch (determineInputType(input)) {
      case InputTypes.Url: {
        return {
          themeOverrides: null,
          mfeLink: input,
        };
      }

      case InputTypes.Json: {
        try {
          const parsed = JSON.parse(input);
          const validatedConfig = parsedModuleInfoSchema.validateSync(parsed);

          for (let i = 0; i <= validatedConfig.moduleConfig.length; i++) {
            try {
              const config = validatedConfig.moduleConfig[i];

              const evalCondition = config.condition
                ? new Function(config.condition)
                : () => false;

              if (!evalCondition()) {
                continue;
              }

              return {
                themeOverrides: config.theme,
                mfeLink: config.mfeEndpoint,
              };
            } catch {
              continue;
            }
          }

          throw new InvalidInputError(
            'Cannot parse Customer federated module - no valid moduleConfig found'
          );
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            throw new InvalidInputError(
              'Cannot parse Customer federated module - moduleConfig cannot be validated'
            );
          }
          throw error;
        }
      }

      case InputTypes.Invalid:
      default: {
        throw new InvalidInputError(
          'Cannot parse Customer federated module - invalid input type'
        );
      }
    }
  }
}
