import {
  ICountrySettings,
  ICustomer,
  ProductConfigurationType,
} from '@tailoor.mfe.monorepo/interfaces';
import { decodeJwt } from 'jose';
import Cookies from 'js-cookie';
import { CookieStore, LocalStorageStore, SessionStorageStore } from './stores';

const env = {
  consumerKey: process.env.NX_REACT_APP_CONSUMER_STORAGE_KEY!,
  customerKey: process.env.NX_REACT_APP_CUSTOMER_STORAGE_KEY!,
  customerIdKey: process.env.NX_REACT_APP_CUSTOMER_ID_STORAGE_KEY!,
  sessionKey: process.env.NX_REACT_APP_SESSION_KEY!,
  authKey: process.env.NX_REACT_APP_AUTH_TOKEN!,
  authTokenBu: process.env.NX_REACT_APP_AUTH_TOKEN_BU!,
  productConfigTypeKey: process.env.NX_REACT_APP_PRODUCT_CONFIG_TYPE_KEY!,
};

/*
 * evaluateHostname return information on the current hostname
 * if internal (tailoor.com) then the domain property is the hostname (i.e: sunflower.tailoor.com)
 * if on a custom domain then the `domain` property is the first level domain (i.e: lanieri.com)
 *
 * The `domain` property returned is used to set cookies options so that cookies for customers on same first level domain
 * would not collide with each other.
 */

const evaluateHostname = (hostname: string) => {
  if (hostname.length < 2) {
    throw new Error('hostname length less then 2');
  }

  const domain = hostname.split('.').slice(-2).join('.');
  const isInternal = domain === 'tailoor.com';

  if (isInternal) {
    return {
      domain: hostname,
      isInternal,
    } as const;
  }
  return {
    domain,
    isInternal,
  } as const;
};

const { domain, isInternal } = evaluateHostname(window.location.hostname);

const defaultCookieOptions: Cookies.CookieAttributes = {
  domain,
  expires: 365,
  secure: true,
  sameSite: 'none',
  path: '/',
};

function setCookieExpiration(
  opt: Cookies.CookieAttributes,
  timestamp?: number
): Cookies.CookieAttributes {
  if (!timestamp) {
    return opt;
  }
  const expiration = new Date(timestamp * 1000);
  return {
    ...opt,
    expires: expiration,
  };
}

const getCustomerConsumerSettingsKey = (id?: number): string => {
  if (id && isInternal) {
    return `${env.consumerKey}_settings_${id}`;
  }
  return `${env.consumerKey}_settings`;
};

/* Store key = "tlr_customer_id"
 *
 * It represents the current customer id in cookies.
 * It is used mainly for comparing the most recent obtained customer id from CSM-01 with the stored value.
 * If the customer has changed then some business logic has to be executed. (i.e: invalidate sessionKey)
 */
export const getSavedCustomerId = () =>
  CookieStore.get<number>(env.customerIdKey);
export const updateCustomerId = (id: number) =>
  CookieStore.update(env.customerIdKey, id);
export const removeCustomerId = () => CookieStore.remove(env.customerIdKey);

/* Store key = "tlr_customer"
 *
 * It represents the current customer as ICustomer object in sessionStorage.
 * It gets updated from a successfull response of CSM-01.
 */
export const getCustomer = () =>
  SessionStorageStore.get<ICustomer>(env.customerKey);
export const updateCustomer = (newCustomer: ICustomer) =>
  SessionStorageStore.update(env.customerKey, newCustomer);
export const removeCustomer = () => SessionStorageStore.remove(env.customerKey);

/* Store key = "tlr_consumer_settings" or `tlr_consumer_settings_${customerId}`
 *
 * It represents the consumer user country & currency preferences in the form of a ICountrySettings object in localStorage.
 * It is used for persisting those configurations while navigating across external applications (i.e: wordpress lanieri.com)
 * and between different sessions.
 */
export const getConsumerSettings = (id?: number) =>
  LocalStorageStore.get<ICountrySettings>(getCustomerConsumerSettingsKey(id));
export const updateConsumerSettings = (settings: ICountrySettings) => {
  const id = getSavedCustomerId();
  const key = getCustomerConsumerSettingsKey(id);
  LocalStorageStore.update(key, settings);
};
export const removeConsumerSettings = () => {
  const id = getSavedCustomerId();
  const key = getCustomerConsumerSettingsKey(id);
  LocalStorageStore.remove(key);
};

/* Store key = "sessionKey"
 *
 * It holds the session uuid that is received from server api responses.
 * The stored id is used to keep a statefull session on the server i.e: the cart for anonymous users
 */

export const getCurrentSessionKey = () =>
  CookieStore.get<string>(env.sessionKey);
export const updateSessionKey = (newSession: string) => {
  CookieStore.update(env.sessionKey, newSession, {
    domain,
    secure: true,
    sameSite: 'none',
    path: '/',
  });
  SessionStorageStore.update(env.sessionKey, newSession);
};
export const removeSessionKey = () => {
  CookieStore.remove(env.sessionKey, {
    domain,
    secure: true,
    sameSite: 'none',
    path: '/',
  });
  SessionStorageStore.remove(env.sessionKey);
};

/* Store key = "tlr_auth"
 *
 * It holds the jwt authorization token that identifies a logged-in user in localStorage.
 * It is stored after a successfull authentication.
 * The value gets saved with the same key in cookie for keeping the authorized session in external applications.
 */
export const getBearerToken = () => CookieStore.get<string>(env.authKey);
export const updateBearerToken = (newToken: string) => {
  const decoded = decodeJwt(newToken);
  const options = setCookieExpiration(defaultCookieOptions, decoded.exp);
  CookieStore.update(env.authKey, newToken, options);
};
export const removeBearerToken = () => {
  LocalStorageStore.remove(env.authKey);
  CookieStore.remove(env.authKey, { path: '/', domain });
};

/* Store key = "tlr_consumer"
 *
 * It holds authorization data coming from a successfull login operation.
 * It will be completely deprecated once all the external application partners will fully adopt tlr_auth.
 * There is no get method since the same data is hold in queryCache under "USR-CNS-01"
 */
export const updateConsumer = (
  displayName: string,
  email: string,
  token: string
) => {
  const decoded = decodeJwt(token);
  const options = setCookieExpiration(defaultCookieOptions, decoded.exp);
  CookieStore.update(env.consumerKey, { displayName, email, token }, options);
};
export const removeConsumer = () => {
  CookieStore.remove(env.consumerKey, { path: '/', domain });
};

/* Store key = "order_id_key"
 *
 * It stores the id of the most recent order that has been placed.
 * It helps storing the order id during the checkout process.
 * Gets set on checkout page and consumed in the thankyou page
 */
export const getOrderId = () => SessionStorageStore.get<string>('order_id_key');
export const removeOrderId = () => SessionStorageStore.remove('order_id_key');
export const updateOrderId = (id: number) =>
  SessionStorageStore.update('order_id_key', String(id));

/* Store key = "st_key"
 *
 * It stores the public key and secret for Stripe payments
 */
export const getStripeData = () =>
  LocalStorageStore.get<{ pk: string; sc: string }>('st_key');
export const updateStripeData = (data: { pk?: string; sc?: string }) =>
  LocalStorageStore.update('st_key', data);
export const removeStripeData = () => LocalStorageStore.remove('st_key');

/* Store key = "config_tutorial"
 *
 * Based on this key value we show a configurator tutorial.
 * The first time a user closes the tutorial modal the key gets set to 'true'
 */
export const getConfigTutorial = () => LocalStorageStore.get('config-tutorial');
export const updateConfigTutorial = (v: string) =>
  LocalStorageStore.update('config-tutorial', v);

/* Store key = "tlr_auth_bu"
 *
 * It stores in sessionStorage the JWT access token of a business user
 * Differs from tlr_auth because there is no need to share the access token between all open tabs
 */
export const getBusinessUserToken = () =>
  SessionStorageStore.get(env.authTokenBu);
export const updateBusinessUserToken = (v: string) =>
  SessionStorageStore.update(env.authTokenBu, v);
export const removeBusinessUserToken = () =>
  SessionStorageStore.remove(env.authTokenBu);

/* Store key = "tlr_product_config_type"
 *
 * It stores in sessionStorage the product configuration type for a specific SKU
 */
export const getProductConfigType = () => {
  SessionStorageStore.get(env.productConfigTypeKey);
};
export const updateProductConfigType = (
  newProductType: ProductConfigurationType
) => {
  SessionStorageStore.update(env.productConfigTypeKey, newProductType);
};

/* Store key = "tlr_totem"
 *
 * It stores an integer (0 or 1) to indicate whether it is a totem flow or a starndard flow
 */
export const getTotemFlow = () => CookieStore.get<number>('tlr_totem');
export const updateTotemFlow = (v: string) =>
  CookieStore.update('tlr_totem', v);
export const removeTotemFlow = () => CookieStore.remove('tlr_totem');
